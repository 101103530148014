#scrollable-body{
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: scroll;
    padding-top: 5%;
    padding-bottom: 50%;
}

#company-tag{
    font-size: 0.8em;
    color: white;
}
.company{
    padding-top: 2%; 
    min-height: 4vh;
    max-height: 4vh;
    background-color: rgba(1,1,1,0.8);
    text-align: center;
    
}
.sticky-options{
    padding-top: 2%;
    min-height: 10vh;
    max-height: 10vh;
    padding-bottom: 2%;
    background-color: rgba(1,1,1,0.1);
}
.sticky-nav{
    padding-top: 3%;
    width: 100vw;
    display: flex;
    position: fixed;
    min-height: 12vh;
    max-height: 12vh;
    background-color: rgba(1,1,1,0.1);
}
.main-body{
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: clip;
}

.stops-body{
  
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: clip;
  margin-left: -1.5em;
}
#numberPad{
    position: sticky;
    top: 20%;
    left: 50%; 
  }
.button-pad{
    min-width: 15vw;
    max-width: 15vw;
    height: 40px;
    font-size: 0.8em;
    background-color: white;
    color: black;
    border-style: solid;
    border-radius:5px;
    border-color:  black;
    cursor: pointer;
    transition: background-color 0.3s;

}

.button-pad:active {
    background-color: lightgrey;
    transform: translateY(2px); /* Move the button down slightly */
}